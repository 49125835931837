'use client';

import clsx from 'clsx';
import Script from 'next/script';

export interface ReforestProps {
  type: 'traveller' | 'destination';
  method: 'calculator' | 'dashboard';
}

export const Reforest = ({type = 'traveller', method = 'calculator'}: ReforestProps) => {

  const config = {
      type: type,
      accountId: "hpVs4AxBpXpYWpBZ3ydaRr",
      successRedirect: "<https://www.visitsunshinecoast.com/sunshine-coast-sustainability-program-dashboard>"
  }

  return (
    <>
    <div id="reforest" className={clsx(method === 'dashboard' && "h-[720px] bg-gray-50")} />
    <Script
        src="https://app.reforest.com.au/reforestclient.js"
        strategy="afterInteractive"
        onReady={() => {
          if(window){
            method === 'calculator' ?
              window.Reforest.createCalculator(config)
              : window.Reforest.createDashboard(config)
            }}
          }
      />
    </>
  );
};

export default Reforest;
